<template>
    <div id="about">
        <section class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center">
                        {{ titleMessage }}
                    </h2>
                    <p>
                        Somos uma igreja em células, que há mais de 20 anos está comprometida em transformar a realidade de seus membros e da comunidade em que estamos inseridos. Localizados próximos do bairro mais populoso de nossa cidade, buscamos viver e falar do amor e do verdadeiro evangelho de Cristo. Para que mais famílias sejam alcançadas! Nos preocupamos com o bem comum e a vida plena de cada amigo, visitante ou parte da nossa família.
                    </p>
                </div>
            </div>
        </section>
    </div>
    <div id="vision">
        <div class="blur">
            <section class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">Visão</h2>
                        <p class="text-center">"Edificar uma igreja Gloriosa, levando pessoas que não tem compromisso com Deus, à se tornarem discípulos fiéis, a fim de que exerçam ministérios fortes e tremendos que glorifiquem a Deus"</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

export default {
	props: {
        titleMessage: String,
        aboutText: String,
        visionText: String
    },
	name: "AboutChurch",    
	data(){
		return {
        };
	}
};
</script>

<style>
#about{
    padding: 60px 0;
    padding-top: 70px;
}
#about, #vision{
    line-height: 2rem;
    margin: 6px 4px;

    display: flex;
    justify-content: center;
    align-items: center;
}
#about p, #vision p{
    font-size: larger;
    color: var(--bs-grey-dark)
}
#about h2, #vision h2{
    font-weight: bold;
}
#vision{
    background: url("../assets/bg.JPG");
    background-size: cover;
    color: white;
    background-attachment: fixed;
    height: 340px;
}
#vision .blur {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    

    border-radius: 4px;
    backdrop-filter: blur(10px) saturate(70%);
}
@media(max-width: 600px){
    #vision{
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
    }
}
</style>