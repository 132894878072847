<template>
    <div class="col-md-6">
        <div class="card mb-3">
            <article class="row g-0">
                <div class="col-md-4">
                    <img v-bind:src="img" class="img-fluid rounded-start" alt="">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">{{ title }}</h5>
                        <p class="card-text">{{ description }}</p>
                        <p class="card-text text-right"><small class="text-muted">{{ date }}</small></p>
                    </div>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
export default{
    name: "CalendarCard",
    props: {
        title: String,
        description: String,
        date: String,
        img: String
    },
    data(){
        return {};
    }
};
</script>

<style>
.card img{
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card .text-right{
    text-align: right;
}
</style>