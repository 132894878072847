<template>
    <section id="schedule" class="container">
        <h2>Agenda</h2>
        <div class="row">
            <CalendarCard img="https://cdn.hillsong.com/wp-content/uploads/sites/9/2022/02/28035949/4-leadership-lessons-card-hillsong-collected.jpg" title="Culto da família" description="Venha para o culto de celebração e traga sua familia, Deus tem algo a especial para você!" date="Todo o domingo ás 10h"/>
            <CalendarCard img="https://www.oliberal.com/image/contentid/policy:1.539327:1653408806/orac-o.png?f=2x1&$p$f=d0e41b6&w=1500&$w=f075b93" title="Oração" description="Um momento com Deus onde oramos como igreja para que através de nós a vontade Dele seja feita" date="Terças ás 20h"/>
        </div>
    </section>
</template>
<script>
import CalendarCard from "./CalendarCard.vue";
export default{
    name: "ChurchCalendar",
    components: {
        CalendarCard,
    },
    data(){
        return {};
    }
};
</script>

<style>
#schedule{
    border-radius: 4px;
    padding: 6px;
    padding-top: 70px;
}
#schedule h2{
    font-weight: bold;
    text-align: center;
}
.card{
    padding: 6px;
}
</style>