<template>
    <footer class="py-3 my-4">
        <ul class="nav justify-content-center border-bottom pb-3 mb-3">
            <li class="nav-item"><a href="#banner" class="nav-link px-2 text-muted">Inicio</a></li>
            <li class="nav-item"><a href="#about" class="nav-link px-2 text-muted">Sobre</a></li>
            <li class="nav-item"><a href="#schedule" class="nav-link px-2 text-muted">Agenda</a></li>
            <li class="nav-item"><a href="#map" class="nav-link px-2 text-muted">Localização</a></li>
        </ul>
        <p class="text-center text-muted">© 2023 Igreja Restauração Comunidade Cristã</p>
  </footer>

</template>
<script>
export default{
    name: "ChurchFooter",
    data(){
        return {};
    }
};
</script>