<template>
    <div id="banner">
        <div id="main-content">
            <div id="principal-text text-center">
                <h1 class="text-bold">{{ titleMessage }}</h1>
                <h3>{{ subTitle }}</h3>
            </div>
        </div>
        <div id="social-media">
            <a class="instagram" href="https://instagram.com/restaura.comunidade?igshid=MDM4ZDc5MmU=" target="_blank"><i class="fa fa-instagram"></i></a>
            <a class="facebook" href="https://www.facebook.com/Igreja.Restauracao.Carapicuiba/" target="_blank"><i class="fa fa-facebook"></i></a>
            <a class="youtube" href="https://m.youtube.com/channel/UC6llOM5SJCBa595D0aNO6Cg?fbclid=PAAaYafxaCc4dduPpqQ-NKyBOBHmykZG19kmUt3DPXSeMQuVAdyEilJ2HhM0g" target="_blank"><i class="fa fa-youtube"></i></a>
        </div>
    </div>
</template>
<script>

export default{
    name:"BannerPrincipal",
	props: {
		titleMessage: String,
		subTitle: String
	},
	data(){
		return {
            
		};
	}
};
</script>
<style>
#banner{
    height: calc(100vh - 80px);
    padding: 4px;
    box-shadow: rgb(209 209 209) -1px -1px 7px 0px;
    color: white;
    background-attachment: fixed;
    background-image: url('https://cdn.hillsong.com/wp-content/uploads/2020/03/26054614/VS20-DotCom-Header.gif');
    background-repeat: no-repeat;
    background-size: cover;
    
}
#main-content{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
}
#social-media{
    width: 100%;
    height: 20%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
#social-media a{
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid gray;
    color: #d8d8d8;

    height: 30px;
    width: 30px;
    transition: all 0.8s ease;
    margin: 4px;
}
#social-media .instagram:hover{
    box-shadow: rgb(230, 88, 201) -1px -1px 7px 0px;
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
#social-media .facebook:hover{
    box-shadow: #4267B2 -1px -1px 7px 0px;
    background: #4267B2;
}
#social-media .youtube:hover{
    box-shadow: #FF0000 -1px -1px 7px 0px;
    background: #FF0000;
}
#text-bold{
    font-weight: bold;
}
@media(max-width: 600px){
    #banner{
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
    }
}
</style>