<template>
  <div>
    <NavbarIgreja/>
    <BannerPrincipal titleMessage="Igreja Restauração" subTitle="Seja bem vindo"/>
    <AboutChurch titleMessage="Sobre"/>
    <ChurchCalendar/>
    <ChurchMap/>
    <ChurchFooter />
  </div>
  
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";

import BannerPrincipal from "./components/BannerPrincipal.vue";
import NavbarIgreja from "./components/NavbarIgreja.vue";
import AboutChurch from "./components/AboutChurch.vue";
import ChurchCalendar from "./components/ChurchCalendar.vue";
import ChurchMap from "./components/ChurchMap.vue";
import ChurchFooter from "./components/ChurchFooter.vue";


export default {
	name: "App",
	components: {
		NavbarIgreja,
		BannerPrincipal,
    AboutChurch,
    ChurchCalendar,
    ChurchMap,
    ChurchFooter
	}
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
