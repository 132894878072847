<template>
    <header id="header">
        <a id="logo" href="">
            <img width="180px" src="../assets/logo.png" alt="">
        </a>
        <nav id="nav" :class="isActiveMenu ? 'active' : ''">
            <button :aria-label="isActiveMenu? 'fechar menu' : 'abrir menu'" :aria-expanded="isActiveMenu" aria-controls="menu" aria-haspopup="true" @click="toggleMenu" @touchstart="toggleMenu" id="btn-mobile">
                <span id="hamburger"></span>
            </button>
            <ul id="menu" role="menu">
                <li @click="toggleMenu"><a href="#about">Sobre</a></li>
                <li @click="toggleMenu"><a href="#schedule">Eventos</a></li>
                <li @click="toggleMenu"><a href="#map">Localidade</a></li>
            </ul>
        </nav>
    </header>
</template>

<script>

export default {
	name: "NavbarIgreja",
	props: {
		msg: String
	},
	data(){
		return {
			isActiveMenu: false
		};
            
	},
	methods:{
		toggleMenu(event){
			if(event.type === "touchstart") event.preventDefault();
			this.isActiveMenu = !this.isActiveMenu;
		}
	}
};
</script>


<style>
a{
    text-decoration: none;
    font-family: sans-serif;

    font-size: larger;
    color: var(--bs-grey-dark)

}
#header a img{
    width: 200px;
}
a:hover{
    color:var(--bs-info)
}
#logo{
    font-size: 1.5rem;
    font-weight: bold;
}
#header{
    box-sizing: border-box;
    height: 70px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    background: #ffffffa6;
    width: 100vw;
    z-index: 2;
}
#menu{
    display: flex;
    list-style: none;
    gap: .5rem;
    margin-bottom: 0px;
}
#menu li a{
    display: block;
    padding: .5rem;
}
#btn-mobile{
    display: none;
}

@media(max-width: 600px){
    #header{
        background: #FFF;
        z-index: 2;
    }
    #menu{
        display: block;
        position: absolute;
        width: 100%;
        top: 70px;
        right: 0px;
        height: 0px;
        transition: all .4s;
        z-index: 1000;
        visibility: hidden;
        overflow-y: hidden;
        background: #FFF;
        text-align: center;
    }
    #nav.active #menu{
        height: calc(3rem + 90px);
        visibility: visible;
        overflow-y: auto;
        background: #FFF;
        padding-left: 2px;
    }
    #menu a{
        padding: 1rem 0;
        margin: 0 1rem;
    }

    #btn-mobile{
        display: flex;
        padding: .5rem 1rem;
        font-size: 1rem;
        border: none;
        background: none;
        cursor: pointer;

    }
    #hamburger{
        border-top: 2px solid #343a40;
        width: 20px;
    }
    #hamburger::after, #hamburger::before{
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        background: #343a40;
        margin-top: 5px;
        transition: .3s;
        position: relative;
    }
    #nav.active #hamburger{
        border-top-color: transparent;
    }
    #nav.active #hamburger::before{
        transform: rotate(135deg);
        background:var(--bs-info);
    }
    #nav.active #hamburger::after{
        transform: rotate(-135deg);
        background:var(--bs-info);
        top: -7px;
    }
}
</style>
