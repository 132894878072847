<template>
    <section id="map" class="container">
        <h2 class="text-center text-bold">Localidade</h2>
        <div class="row">
            <div class="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.845486667961!2d-46.82925098518267!3d-23.538059166616925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf01d52ef297f3%3A0x8f9b1078eb90bdb1!2sR.%20Padre%20%C3%81ureliano%2C%20125%20-%20Vila%20Silviania%2C%20Carapicu%C3%ADba%20-%20SP%2C%2006321-470!5e0!3m2!1spt-BR!2sbr!4v1677115557843!5m2!1spt-BR!2sbr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>

    </section>
</template>

<style>
#map{
    padding-top: 70px;
}
.text-bold{
    font-weight: bold;
}
</style>

<script>
export default{
    name: "ChurchMap",
    data(){
        return {};
    }
};
</script>